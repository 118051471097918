import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Doku from '../views/Doku.vue'
import Controls from "@/views/Controls.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Doku
  },
  {
    path: '/controls',
    name: 'Controls',
    component: Controls
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
