<script>
import DeviceComponent from '@/components/DeviceComponent.vue'

export default {
  components: {
    DeviceComponent
  }
}
</script>

<template>
  <div>
    <DeviceComponent
        deviceName="pump_heat_exchanger"
        description="Pumpe für den Wärmetauscher"
        onImage="@/assets/pumpRunning.gif"
        offImage="@/assets/pumpStopped.png"
    />
    <DeviceComponent
        deviceName="oil_heater"
        description="Pumpe für den Wärmetauscher"
        onImage="@/assets/pumpRunning.gif"
        offImage="@/assets/pumpStopped.png"
    />
    <DeviceComponent
        deviceName="pump_miner"
        description="Pumpe für den Wärmetauscher"
        onImage="@/assets/pumpRunning.gif"
        offImage="@/assets/pumpStopped.png"
    />
    <DeviceComponent
        deviceName="switch"
        description="Pumpe für den Wärmetauscher"
        onImage="@/assets/pumpRunning.gif"
        offImage="@/assets/pumpStopped.png"
    />
  </div>
</template>

<style scoped></style>
