<template>
  <div class="grafana-chart-container">
    <iframe :src="src" width="450" height="200" frameborder="0"></iframe>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  name: 'GrafanaChart',
  props: {
    src: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.grafana-chart-container {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  align-items: center; /* Centers children horizontally */
  margin-bottom: 20px; /* Adjust as needed */
}

iframe {
  width: 100%; /* Allows the iframe to fill the container */
  min-width: 450px; /* Minimum width */
  height: auto; /* Allows the height to adjust based on aspect ratio */
  min-height: 200px; /* Minimum height */
  aspect-ratio: 450 / 200; /* Maintains aspect ratio, adjust as needed */
}

.description {
  margin-top: 10px; /* Adds space between the iframe and the description */
  text-align: center; /* Center-aligns the description text */
  width: 100%; /* Ensures the description takes full width of its container */
  /* Additional styling for the description can be added here */
}
</style>

