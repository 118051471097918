<script>
import axios from 'axios';

export default {
  name: 'DeviceComponent',
  props: {
    deviceName: {
      type: String,
      required: true
    },
    description: String,
    onImage: String,
    offImage: String
  },
  data() {
    return {
      currentState: '',
      apiUrl: 'http://172.16.4.15:8010/devices'
    };
  },
  mounted() {
    this.fetchState();
    this.interval = setInterval(this.fetchState, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async fetchState() {
      try {
        const response = await axios.get(`${this.apiUrl}/${this.deviceName}/state`);
        this.currentState = response.data.state;
      } catch (error) {
        console.error('Error fetching device state: ', error);
      }
    },
    async controlDevice(state) {
      console.log(state)
    }
  }
};
</script>

<template>
  <div class="device">
    <div class="device-info">
      <img v-if="this.currentState === 'ON'" src="@/assets/pumpRunning.gif" alt="Device Image" class="device-image">
      <img v-else src="@/assets/pumpStopped.png" alt="Device Image" class="device-image">
      <h3>{{ deviceName }}</h3>
      <p>{{ description }}</p>
    </div>
    <div class="device-control">
      <button @click="controlDevice('ON')" :disabled="currentState ==='ON'">Turn ON</button>
      <button @click="controlDevice('OFF')" :disabled="currentState ==='OFF'">Turn OFF</button>
    </div>
  </div>
</template>


<style scoped>
.device {
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.device-info {
  flex: 1;
}

.device-image {
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
}

.device-control button {
  margin-left: 10px;
}

</style>