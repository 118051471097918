<template>
  <div class="blog-post">
    <h2>{{ headline }}</h2>
    <p v-html="processedDescription"></p>
    <input
        type="number"
        v-if="showInput"
        v-model.number="numericInput"
        :min="0"
        :max="7700"
        placeholder="Enter number (0-7700)"
    >
    <button @click="handleButtonClick">{{ buttonText }}</button>
  </div>
</template>

<script>

export default {
  name: 'BlogPost',
  props: {
    headline: String,
    description: String,
    baseLink: String, // The base URL
    isRedirect: Boolean,
    buttonText: String,
    showInput: {
      type: Boolean,
      default: false // Default value is false, so the input is hidden by default
    }
  },
  data() {
    return {
      numericInput: 0 // Default value for the numeric input
    };
  },
  computed: {
    processedDescription() {
      return this.description.replace(/\n/g, '<br>');
    }
  },
  methods: {
    handleButtonClick() {
      const fullLink = this.baseLink + (this.showInput ? `/${this.numericInput}` : '');
      if (this.isRedirect) {
        console.log('Redirecting to', fullLink);
        window.open(fullLink, '_blank').focus();
      } else {
        // Perform a GET request
        fetch(fullLink)
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
      }
    }
  }
};
</script>

/* src/components/BlogPost.vue */

<style scoped>
input[type="number"] {
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  background-color: #1E1E1E;
  color: #E0E0E0;
}

button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0a7f42; /* Customize with your color */
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #096b36; /* Darken color on hover */
}

.blog-post {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #444;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #252525; /* Dark background for each post */
  color: #E0E0E0; /* Light text color for contrast */
}
</style>

