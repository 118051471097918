<script>

import GrafanaChart from "@/components/GrafanaChart.vue";

export default {
  components: {GrafanaChart},

  data() {
    return {
      sliderValue: 50,
      sliderValue1: 7000,
      timeValue: 24
    }
  },
  methods: {
    constructGrafanaURL(panelId) {
      return `https://grafana.varaneo.ch/d-solo/AI3qmWxIk/mining-overview?orgId=1&refresh=5s&from=now-${this.timeValue}h&to=now&panelId=${panelId}`;
    }
  }
}
</script>

<template>
  <div class="slider-container">
    <div class="slider-section">
      <h2>Temperature (°C)</h2>
      <input type="range" v-model="sliderValue" min="20" max="75" step="1">
      <div class="slider-value">{{ sliderValue }}°C</div>
    </div>

    <div class="slider-section">
      <h2>Power (Watts)</h2>
      <input type="range" v-model="sliderValue1" min="1000" max="7500" step="1">
      <div class="slider-value">{{ sliderValue1 }}W</div>
    </div>
  </div>

  <div>
    <select v-model="timeValue">
      <option v-for="hour in 24" :value="hour" :key="hour">{{ hour}}</option>
    </select>
  </div>
  <div class="row">
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(24)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(6)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(26)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(12)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(14)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(18)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(20)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(16)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(22)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(8)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(2)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
    <div class="col-md-6">
      <GrafanaChart
          :src="constructGrafanaURL(10)"
          description="This chart shows an overview of the mining performance over the last 24 hours."/>
    </div>
  </div>


</template>


<style scoped>
.slider-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px auto;
}

.slider-section {
  flex-basis: 45%; /* Adjust this value based on your preference */
  margin: 10px;
}

.slider-section h2 {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 10px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider-value {
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}

.charts-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.chart-row {
  flex: 1;
  min-width: 450px;
  width: 100%;
  max-width: 1000px;
  margin: 0 10px;
}
</style>
