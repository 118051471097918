<template>
  <nav class="navbar">
    <div @click="navigateTo('/')">
      <img src="@/assets/logo_weiss_varaneo.png" alt="Varaneo" class="logo">
    </div>
    <div class="navbar-links">
      <button @click="navigateTo('/')" class="navbar-item">Home</button>
      <button @click="navigateTo('/documentation')" class="navbar-item">Documentation</button>
      <button @click="navigateTo('/controls')" class="navbar-item">Controls</button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    }
  }
}
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
}

.navbar-brand, .logo {
  cursor: pointer;
}

.logo {
  max-width: 159px; /* Adjusted for your logo's aspect ratio */
  height: auto;
}

.navbar-links {
  display: flex;
  margin-left: 1rem;
}

.navbar-item {
  background-color: #444; /* Button background */
  border: none;
  color: #ddd; /* Button text color */
  padding: 0.5rem 1rem;
  margin-left: 2rem;
  cursor: pointer !important;
  transition: background-color 0.3s ease;
}

.navbar-item:hover {
  background-color: #555; /* Button hover background */
  color: #fff; /* Button hover text color */
  cursor: pointer !important;
}
</style>
